.am-grid-text{
    word-wrap: break-word;
    max-width: 80px;
    margin: auto;
}
.column-num-4{
    display: unset!important;
}
.am-grid-icon{
    width: 32px!important;
    height: 32px;
}
.am-grid .am-flexbox .am-flexbox-item .am-grid-item-content .am-grid-item-inner-content .am-grid-icon {
    width: 32px!important;
}