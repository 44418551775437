.login-container {
    background-size:cover;
    right:0px!important;
    left:0px!important;
}

.am-input-extra {
    max-height: 30px!important;
}
.input-container {
    opacity: 1;
}

