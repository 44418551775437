.little-title{
    display:block;
    font-size: smaller;
    color:#333333;
    margin-top: 20px;
    padding-left: 5px;
}


.content{
    background-color: white;
    margin-top: 5px;
    padding: 10px 10px 10px 10px;
    border-radius:5px;
    min-height: 50px;

}