.logo-container{
    /*margin-top: 50px;*/
    text-align:center;
    /*margin-bottom: 20px;*/
    height:250px;
    height:250px;
    background-color: rgba(27, 130, 210, 1);;
}
.am-list-body{
    border-top:0px;
}
.logo-container img {
    margin-top: 40px;
}
