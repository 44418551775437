.error-msg{
    color:#f50;
    padding-left:10px;
    padding-top: 10px;
}

.am-tab-bar{
    position:fixed;
    bottom:0;
    z-index:-1;
    width:100%;
}
.fixd-header.am-navbar{
    position:fixed;
    top:0;
    width:100%;
}
.page-content{
    padding:45px 5px;
}
#chat-page .chat-me .am-list-extra {
    flex-basis:auto;
}
#chat-page .chat-me .am-list-content {
    padding-right:15px;
    text-align:right;
}
.stcick-footer {
    z-index:10;
    position:absolute;
    bottom:0;
    width: 100%;
}
#chat-page .am-grid.am-grid-square .am-grid-item .am-grid-item-inner-content .am-grid-icon{
    width:auto!important;
}

.element::-webkit-scrollbar { width: 0 !important }

.login-container {
    background-size:cover;
    right:0px!important;
    left:0px!important;
}

.am-input-extra {
    max-height: 30px!important;
}
.input-container {
    opacity: 1;
}


.am-input-extra {
    max-height: 30px!important;
}

.am-badge-text {
    background-color: red;
}

.am-navbar {
    background-color: rgba(27, 130, 210, 1);
}

.am-grid-text{
    word-wrap: break-word;
    max-width: 80px;
    margin: auto;
}
.column-num-4{
    display: unset!important;
}
.am-grid-icon{
    width: 32px!important;
    height: 32px;
}
.am-grid .am-flexbox .am-flexbox-item .am-grid-item-content .am-grid-item-inner-content .am-grid-icon {
    width: 32px!important;
}
.am-list-extra{
    flex-basis: 20% !important;
}
.am-list-item img{
    height: 66px;
    width: 66px;
}
.am-list-item .am-list-line .am-list-brief{
    font-size: 12px;
}
.swal-modal{
    border-radius:10px;
    width: 240px;
    height: 115px;
}
.swal-title {
    margin: 0px!important;
    font-size: 18px;
    margin-bottom: 28px;
    padding: 10px 10px 6px 10px;
}
.swal-text{
    color: #a7a7a7;
    font-size: 14px;
    width: 100%;
    text-align: center;
    z-index: 999;
}
.swal-footer{
    text-align: center;
    margin-top: 0px;
    padding: 5px 0px 0px 0px;
}
.swal-button{
    padding: 16px 44px;
    border-radius: 0px 0px 10px 10px;
    background-color: white;

    outline: 0!important;
    border-top-color: #eeeeee;
    border-top-style: solid;
    border-top-width: 0.5px;
    border-right-color: rgb(167, 167, 167);
    border-right-style: solid;
    border-right-width: 0px;
    border-bottom-color: rgb(167, 167, 167);
    border-bottom-style: solid;
    border-bottom-width: 0px;
    border-left-color: rgb(167, 167, 167);
    border-left-style: solid;
    border-left-width: 0px;
    border-radius: 0px 0px 10px 0px;
    color: #0e80d2;
    background-color: white!important;
}

.swal-button-container{
    margin: 0px;
}
.swal-button--cancel{
    outline:0!important;
    border-top-color: #eeeeee;
    border-top-style: solid;
    border-top-width: 0.5px;
    border-right-color: #eeeeee;
    border-right-style: solid;
    border-right-width: 0.5px;
    border-bottom-color: rgb(167, 167, 167);
    border-bottom-style: solid;
    border-bottom-width: 0px;
    border-left-color: rgb(167, 167, 167);
    border-left-style: solid;
    border-left-width: 0px;
    border-radius: 0px 0px 0px 10px;
    color: #2a2b2c;
    background-color: white!important;
}
/*.swal-button--danger{*/
    /*outline: 0!important;*/
    /*border-top-color: #eeeeee;*/
    /*border-top-style: solid;*/
    /*border-top-width: 0.5px;*/
    /*border-right-color: rgb(167, 167, 167);*/
    /*border-right-style: solid;*/
    /*border-right-width: 0px;*/
    /*border-bottom-color: rgb(167, 167, 167);*/
    /*border-bottom-style: solid;*/
    /*border-bottom-width: 0px;*/
    /*border-left-color: rgb(167, 167, 167);*/
    /*border-left-style: solid;*/
    /*border-left-width: 0px;*/
    /*border-radius: 0px 0px 10px 0px;*/
    /*color: #0e80d2;*/
    /*background-color: white!important;*/
/*}*/
.swal-button--roll{
    display: none;
}

.logo-container{
    /*margin-top: 50px;*/
    text-align:center;
    /*margin-bottom: 20px;*/
    height:250px;
    height:250px;
    background-color: rgba(27, 130, 210, 1);;
}
.am-list-body{
    border-top:0px;
}
.logo-container img {
    margin-top: 40px;
}

.little-title{
    display:block;
    font-size: smaller;
    color:#333333;
    margin-top: 20px;
    padding-left: 5px;
}


.content{
    background-color: white;
    margin-top: 5px;
    padding: 10px 10px 10px 10px;
    border-radius:5px;
    min-height: 50px;

}
.am-list-extra {
    flex-basis: 36%!important;
}
