.error-msg{
    color:#f50;
    padding-left:10px;
    padding-top: 10px;
}

.am-tab-bar{
    position:fixed;
    bottom:0;
    z-index:-1;
    width:100%;
}
.fixd-header.am-navbar{
    position:fixed;
    top:0;
    width:100%;
}
.page-content{
    padding:45px 5px;
}
#chat-page .chat-me .am-list-extra {
    flex-basis:auto;
}
#chat-page .chat-me .am-list-content {
    padding-right:15px;
    text-align:right;
}
.stcick-footer {
    z-index:10;
    position:absolute;
    bottom:0;
    width: 100%;
}
#chat-page .am-grid.am-grid-square .am-grid-item .am-grid-item-inner-content .am-grid-icon{
    width:auto!important;
}

.element::-webkit-scrollbar { width: 0 !important }
