.am-list-extra{
    flex-basis: 20% !important;
}
.am-list-item img{
    height: 66px;
    width: 66px;
}
.am-list-item .am-list-line .am-list-brief{
    font-size: 12px;
}
.swal-modal{
    border-radius:10px;
    width: 240px;
    height: 115px;
}
.swal-title {
    margin: 0px!important;
    font-size: 18px;
    margin-bottom: 28px;
    padding: 10px 10px 6px 10px;
}
.swal-text{
    color: #a7a7a7;
    font-size: 14px;
    width: 100%;
    text-align: center;
    z-index: 999;
}
.swal-footer{
    text-align: center;
    margin-top: 0px;
    padding: 5px 0px 0px 0px;
}
.swal-button{
    padding: 16px 44px;
    border-radius: 0px 0px 10px 10px;
    background-color: white;

    outline: 0!important;
    border-top-color: #eeeeee;
    border-top-style: solid;
    border-top-width: 0.5px;
    border-right-color: rgb(167, 167, 167);
    border-right-style: solid;
    border-right-width: 0px;
    border-bottom-color: rgb(167, 167, 167);
    border-bottom-style: solid;
    border-bottom-width: 0px;
    border-left-color: rgb(167, 167, 167);
    border-left-style: solid;
    border-left-width: 0px;
    border-radius: 0px 0px 10px 0px;
    color: #0e80d2;
    background-color: white!important;
}

.swal-button-container{
    margin: 0px;
}
.swal-button--cancel{
    outline:0!important;
    border-top-color: #eeeeee;
    border-top-style: solid;
    border-top-width: 0.5px;
    border-right-color: #eeeeee;
    border-right-style: solid;
    border-right-width: 0.5px;
    border-bottom-color: rgb(167, 167, 167);
    border-bottom-style: solid;
    border-bottom-width: 0px;
    border-left-color: rgb(167, 167, 167);
    border-left-style: solid;
    border-left-width: 0px;
    border-radius: 0px 0px 0px 10px;
    color: #2a2b2c;
    background-color: white!important;
}
/*.swal-button--danger{*/
    /*outline: 0!important;*/
    /*border-top-color: #eeeeee;*/
    /*border-top-style: solid;*/
    /*border-top-width: 0.5px;*/
    /*border-right-color: rgb(167, 167, 167);*/
    /*border-right-style: solid;*/
    /*border-right-width: 0px;*/
    /*border-bottom-color: rgb(167, 167, 167);*/
    /*border-bottom-style: solid;*/
    /*border-bottom-width: 0px;*/
    /*border-left-color: rgb(167, 167, 167);*/
    /*border-left-style: solid;*/
    /*border-left-width: 0px;*/
    /*border-radius: 0px 0px 10px 0px;*/
    /*color: #0e80d2;*/
    /*background-color: white!important;*/
/*}*/
.swal-button--roll{
    display: none;
}
